<template>
  <v-container>
    <v-row>
      <v-dialog v-model="bue_dialog" persistent max-width="800">
        <v-card>
          <v-card-text class="pa-8">
            <v-row class="mb-4" justify="space-between" align="center">
              <v-col cols="12" md="6">
                <span style="font-size: 1.7rem; line-height: 2.4rem; padding-top: 8px">Оберіть необхідну кількість СМС</span>
              </v-col>
              <v-col cols="12" md="4" class="text-right">
                <span
                    class="font-weight-light"
                    style="font-size: 5rem; font-weight: 500"
                    :class="`${color}--text`"
                    v-text="count_to_bue"
                />
              </v-col>
            </v-row>

            <v-slider
                v-model="count_to_bue"
                :color="color"
                track-color="grey"
                class="mb-4"
                min="30"
                max="5000"
            >
              <template v-slot:prepend>
                <v-icon :color="color" @click="decrement">
                  mdi-minus
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon :color="color" @click="increment">
                  mdi-plus
                </v-icon>
              </template>
            </v-slider>
            <v-row>
              <v-col cols="4" class="col-name">К-сть СМС:</v-col>
              <v-col cols="8" class="col-value">{{ count_to_bue }}</v-col>
              <v-col cols="4" class="col-name">Ціна за СМС:</v-col>
              <v-col cols="8" class="col-value">{{ price }}</v-col>
              <v-col cols="4" class="col-name">Сума знижки:</v-col>
              <v-col cols="8" class="col-value">{{ discount * count_to_bue | formatNumber}}</v-col>
              <v-col cols="4" class="col-name">До оплати</v-col>
              <v-col cols="8" class="col-value">{{ count_to_bue * price - (discount * count_to_bue) | formatNumber}}</v-col>
              <v-col cols="12" class="px-0">
                <v-btn depressed block class="success" @click.stop="bueSMS">Замовити</v-btn>
              </v-col>
              <v-col cols="12" class="px-0 py-0">
                <v-btn depressed block text @click.stop="closeDialog">Відмінити</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col cols="12">
        <v-card>
          <v-card-text class="d-flex align-center">
            <v-avatar class="grey darken-1 white--text font-weight-bold mr-3">
              СМС
            </v-avatar>
            <div class="mx-3">
              <div style="display: flex; margin-bottom: 6px">
                <div><span style="font-size: .9rem">Доступно:</span> <span class="success--text" style="font-size: 1.05rem">
                  {{ balance_count }} смс. (Борг: {{balance_sum | formatNumber}} грн.)
                </span></div>
              </div>
             <div>
               <v-btn small depressed tile @click.stop="bue_dialog = true">Замовити</v-btn>
             </div>
            </div>
            <v-spacer></v-spacer>
            <MainModal
                :main="{ component: 'MediaSend', title: 'Налаштування розсилки' }"
                :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonStyle: 'width: 54px; height: 54px;' }"
                :maxWidth="1400"
                @updateItemModal="updateItemModal"
            />
            <MainModal
                :main="{ component: 'MediaSend', title: 'Налаштування розсилки' }"
                :button="{ iconActivator: false, buttonHidden: true }"
                :item="selectedItem"
                :maxWidth="1400"
                :modal="dialog_id === 1"
                @updateItemModal="updateItemModal"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs class="custom-tabs" color="success">
          <v-tab class="text-left justify-start">
            Розсилка
          </v-tab>
          <v-tab class="text-left justify-start">
            Історія
          </v-tab>

          <v-tab-item class="pa-0">
            <v-card
                v-for="(item, idx) in items" :key="idx" tile elevation="2"
                class="mb-2 d-flex flex-wrap flex-row row-card"
                style="border-left: 3px solid #5bb55f"
                @click.stop="changeSelectedItem(item)"
            >
              <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <div>Дата відправ.</div>
                <div>{{ item.date | formatDate('DD.MM.YYYY') }}</div>
              </v-col>
              <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <div>Тип повідомлення</div>
                <div class="d-flex align-center">
                  <div class="mr-2">
                    <v-icon size="19">mdi-email-open-outline</v-icon>
                  </div>
                  <div>{{ getMediaSendType(item.media_send_type) }}</div>
                </div>
              </v-col>
              <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <div>Статус</div>
                <div>{{ getMediaSendStatus(item.media_send_status) }}</div>
              </v-col>
              <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <div>Доставлено</div>
                <div>{{ (item.success_count / item.sms_count * 100) | formatNumber }}% (к-сть {{item.sms_count}})</div>
              </v-col>
              <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <div>Фільтр</div>
                <div>
                  <pre style="white-space: normal" v-html="item.filter_string ? item.filter_string : 'Фільтр не встановлений (усі абоненти)'"></pre>
                </div>
              </v-col>
            </v-card>
          </v-tab-item>
          <v-tab-item class="pa-0">
            <v-card
                v-for="(item, idx) in bue_items" :key="idx" tile elevation="2"
                class="mb-2 d-flex flex-wrap flex-row row-card"
                style="border-left: 3px solid #5bb55f"
            >
              <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <div>Дата</div>
                <div>{{ item.date | formatDate('DD.MM.YYYY') }}</div>
              </v-col>
              <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <div>К-сть</div>
                <div>{{ item.counter }}</div>
              </v-col>
              <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <div>Сума</div>
                <div>{{ item.sum | formatNumber }}</div>
              </v-col>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {MEDIA_BUE_CREATE, MEDIA_BUE_FETCH_ALL, MEDIA_SEND_HEADER_FETCH_ALL} from "@/store/actions/media_send";
import {getMediaSendType, getMediaSendStatus} from "@/utils/icons"
import mediaSMSAPI from "@/utils/axios/media_send"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "MediaSend",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getMediaSendHeader',
      bue_items: 'getMediaBue',
    }),
    color () {
      if (this.count_to_bue < 100) return 'indigo'
      if (this.count_to_bue < 200) return 'teal'
      if (this.count_to_bue < 500) return 'green'
      if (this.count_to_bue < 1000) return 'orange'
      return 'red'
    },
    price () {
      if (this.count_to_bue < 100) return 0.64
      if (this.count_to_bue < 200) return 0.64
      if (this.count_to_bue < 500) return 0.64
      if (this.count_to_bue < 1000) return 0.64
      return 0.64
    },
    discount () {
      return 0.64 - this.price
    },
  },
  data() {
    return {
      dialog_id: 0,
      count_to_bue: 30,
      bue_dialog: false,
      selectedItem: null,
      balance_count: 0,
      balance_sum: 0
    }
  },
  methods: {
    ...mapActions(
        {
          fetchItems: MEDIA_SEND_HEADER_FETCH_ALL,
          fetchBueItems: MEDIA_BUE_FETCH_ALL
        }
    ),
    bueSMS() {
      const payload = {
        counter: this.count_to_bue,
        sum: this.count_to_bue * this.price
      }

      this.$store.dispatch(MEDIA_BUE_CREATE, payload)
        .then(() => {
          this.count_to_bue = 0
          this.bue_dialog = false
          this.getBalance()
        })

    },
    getMediaSendStatus,
    getMediaSendType,
    changeSelectedItem(payload) {
      if (payload) {
        this.selectedItem = Object.assign({}, payload)
        this.selectedItem.filter_data = Object.assign({}, payload.filter_data)
        this.dialog_id = 1
      }
    },
    updateItemModal() {
      this.dialog_id = 0
      this.getBalance()
    },
    decrement() {
      this.count_to_bue -= 1
    },
    increment() {
      this.count_to_bue += 1
    },
    closeDialog() {
      this.bue_dialog = false
      this.count_to_bue = 30
    },
    getBalance() {
      mediaSMSAPI.get_sms_balance()
        .then(response => response.data)
        .then(data => {
          this.balance_count = data.count || 0
          this.balance_sum = data.balance || 0
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    }
  },
  created() {
    this.fetchItems()
      .then(() => {
        this.getBalance()
      })
    this.fetchBueItems()
  }
}
</script>

<style scoped lang="scss">
  .custom-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    :deep(.v-window.v-item-group.theme--light) {
      background-color: transparent;
      flex: 1;
    }

    :deep(div[role="tablist"]) {
      background-color: #f5f5f5 !important;
      height: 30px !important;
      //flex: 0;
    }

    :deep(.v-window.v-item-group) {
      height: 100%;
    }

    :deep(.v-window-item--active) {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    :deep(.v-window-item) {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }
  .col-name {
    font-size: 1rem;
    background-color: #eeeeee;
    margin-bottom: 4px;
    color: #2d2d2d;
  }
  .col-value {
    font-size: 1.2rem;
    background-color: #f5f5f5;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .row-card {
    div > div:nth-child(1) {
      font-size: .68rem;
      font-weight: 400;
      color: #757575
    }
    div > div:nth-child(2) {
      font-size: .84rem;
      color: #4a4a4a;

      span:nth-child(1) {
        font-weight: 500;
        color: #2a9d2f;
      }
      span:nth-child(2) {
        font-weight: 500;
        color: #2d2d2d;
      }
    }
  }
</style>
